// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articulo-article-card-js": () => import("./../../../src/pages/articulo/articleCard.js" /* webpackChunkName: "component---src-pages-articulo-article-card-js" */),
  "component---src-pages-articulo-index-js": () => import("./../../../src/pages/articulo/index.js" /* webpackChunkName: "component---src-pages-articulo-index-js" */),
  "component---src-pages-comunicados-index-js": () => import("./../../../src/pages/Comunicados/index.js" /* webpackChunkName: "component---src-pages-comunicados-index-js" */),
  "component---src-pages-comunicados-subprogram-template-js": () => import("./../../../src/pages/Comunicados/subprogramTemplate.js" /* webpackChunkName: "component---src-pages-comunicados-subprogram-template-js" */),
  "component---src-pages-descuentos-index-js": () => import("./../../../src/pages/descuentos/index.js" /* webpackChunkName: "component---src-pages-descuentos-index-js" */),
  "component---src-pages-eventos-event-template-js": () => import("./../../../src/pages/eventos/eventTemplate.js" /* webpackChunkName: "component---src-pages-eventos-event-template-js" */),
  "component---src-pages-eventos-index-js": () => import("./../../../src/pages/eventos/index.js" /* webpackChunkName: "component---src-pages-eventos-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-padron-index-js": () => import("./../../../src/pages/padron/index.js" /* webpackChunkName: "component---src-pages-padron-index-js" */),
  "component---src-pages-padron-subir-census-js": () => import("./../../../src/pages/padron/subir/census.js" /* webpackChunkName: "component---src-pages-padron-subir-census-js" */),
  "component---src-pages-preguntas-frecuentes-faq-js": () => import("./../../../src/pages/preguntas_frecuentes/FAQ.js" /* webpackChunkName: "component---src-pages-preguntas-frecuentes-faq-js" */),
  "component---src-pages-preguntas-frecuentes-index-js": () => import("./../../../src/pages/preguntas_frecuentes/index.js" /* webpackChunkName: "component---src-pages-preguntas-frecuentes-index-js" */),
  "component---src-pages-programas-index-js": () => import("./../../../src/pages/programas/index.js" /* webpackChunkName: "component---src-pages-programas-index-js" */),
  "component---src-pages-programas-subprogram-template-js": () => import("./../../../src/pages/programas/subprogramTemplate.js" /* webpackChunkName: "component---src-pages-programas-subprogram-template-js" */),
  "component---src-pages-revistas-index-js": () => import("./../../../src/pages/revistas/index.js" /* webpackChunkName: "component---src-pages-revistas-index-js" */),
  "component---src-pages-revistas-magazine-template-js": () => import("./../../../src/pages/revistas/magazineTemplate.js" /* webpackChunkName: "component---src-pages-revistas-magazine-template-js" */),
  "component---src-pages-tickets-details-js": () => import("./../../../src/pages/tickets/details.js" /* webpackChunkName: "component---src-pages-tickets-details-js" */),
  "component---src-pages-tickets-employee-details-js": () => import("./../../../src/pages/tickets/employeeDetails.js" /* webpackChunkName: "component---src-pages-tickets-employee-details-js" */),
  "component---src-pages-tickets-index-js": () => import("./../../../src/pages/tickets/index.js" /* webpackChunkName: "component---src-pages-tickets-index-js" */),
  "component---src-pages-tickets-rfc-name-js": () => import("./../../../src/pages/tickets/RFCName.js" /* webpackChunkName: "component---src-pages-tickets-rfc-name-js" */),
  "component---src-templates-article-template-js": () => import("./../../../src/templates/articleTemplate.js" /* webpackChunkName: "component---src-templates-article-template-js" */),
  "component---src-templates-program-template-js": () => import("./../../../src/templates/programTemplate.js" /* webpackChunkName: "component---src-templates-program-template-js" */)
}

